/*----------------- DASHBOARD -----------------*/
.dashboardH1 {
  font-size: 2.125rem !important;
  letter-spacing: 0.25px !important;
  font-weight: 400 !important;
  color: #616161;
}

.dashboardH1Sub {
  color: #9e9e9e;
}

.cardTitle {
  color: rgba(0, 0, 0, 0.38);
}

.dashboardIcon {
  margin-right: 0.5rem;
}

.cardValue {
  margin-top: 3.875rem !important;
}

.cardContent {
  color: #616161;
  padding-bottom: 0.5rem !important;
  text-align: left;
}

/*----------------- DASHBOARD TABS -----------------*/
.tabH3,
.tabH3Sub {
  color: #616161;
  font-size: 0.875rem !important;
}

.tabH3 {
  font-weight: 500 !important;
  margin-bottom: 0.4rem !important;
}

.previewIcon {
  color: #0057b2;
}

.inactiveChip {
  color: #ffffff !important;
  background-color: #ff505f !important;
}

.activeChip {
  color: #ffffff !important;
  background-color: #007dff !important;
}

/*----------------- CREATE SITE -----------------*/
.questionTitle {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #424242 !important;
}

.questionButton {
  margin-right: 1rem !important;
}

.dialogTitleSub {
  color: #616161 !important;
  font-size: 0.875rem !important;
}
.dialogText {
  color: #202020 !important;
}

.dialogExit {
  float: right;
}

.dialogTextTitle {
  color: #202020 !important;
  font-weight: 500 !important;
}

.dialogSubText {
  margin-bottom: 2rem !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.875rem;
  color: #212b36;
}

.css-16ubnlw-MuiStepLabel-labelContainer {
  text-align: center;
}

.searchButton {
  height: 40px;
}

.helperText {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 0.3rem !important;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.css-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
  cursor: pointer !important;
}

.publishList {
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.publishButton {
  text-decoration: underline !important;
  color: #0057b2;
}

.publishButton:hover {
  color: #001c3a;
}

.roleDivider {
  margin: 1rem 0 !important;
}

.roleBox {
  width: 19% !important;
  border: 1px solid #d3dae6 !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  margin-right: 0.5rem !important;
}

.roleTitle {
  font-weight: 500 !important;
  color: #343741 !important;
}

.roleDescriptionDiv {
  margin: 1rem 0;
  height: 220px;
}

.roleDescription {
  font-size: 0.875rem !important;
}

.confirmDivider {
  margin: 1rem 0 !important;
}

.informationDivider {
  margin-bottom: 0.5rem !important;
}

.confirmH3 {
  font-size: 20px !important;
  color: #707070 !important;
}

.confirmTitle {
  font-size: 12px !important;
  color: #707070 !important;
}

.confirmDate {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #424242 !important;
}

.confirmInformation {
  font-size: 14px !important;
  color: #202020 !important;
  font-weight: 500 !important;
}

.confirmDiv {
  margin-bottom: 1.5rem !important;
}

.confirmEditIcon {
  color: #0057b2;
  font-size: 16px !important;
}

/*----------------- GLOBAL STYLING -----------------*/
.mainContainer {
  margin-top: 2.5rem;
}

.DeliveryModeGraphic {
  margin-top: 1rem;
  width: 100%;
}

/*----------------- NAVBAR -----------------*/
.navbar {
  background: #ffffff;
}

.navbarLogo {
  max-width: 100px;
  margin-right: 2rem;
}

.navbarTitle {
  color: #424242 !important;
}

.navbarTitle:hover {
  background: #f5f5f5 !important;
}

.navbarCreateButton {
  margin-right: 1.5rem !important;
}

.navbarUser {
  color: #424242;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  margin-right: 0.5rem !important;
}

/*----------------- DASHBOARD -----------------*/
.dashboardH1 {
  font-size: 2.125rem !important;
  letter-spacing: 0.25px !important;
  font-weight: 400 !important;
  color: #616161;
}

.dashboardH1Sub {
  color: #9e9e9e;
}

.cardTitle {
  color: rgba(0, 0, 0, 0.38);
}

.dashboardIcon {
  margin-right: 0.5rem;
}

.cardValue {
  margin-top: 3.875rem !important;
}

.cardContent {
  color: #616161;
  padding-bottom: 0.5rem !important;
  text-align: left;
}

/*----------------- DASHBOARD TABS -----------------*/
.tabH3,
.tabH3Sub {
  color: #616161;
  font-size: 0.875rem !important;
}

.tabH3 {
  font-weight: 500 !important;
  margin-bottom: 0.4rem !important;
}

.previewIcon {
  color: #0057b2;
}

/*----------------- DASHBOARD TABS - MANAGE TABLE -----------------*/
.manageTableUOS {
  color: #0057b2;
  font-weight: 500;
}

.Inactive {
  color: #ffffff !important;
  background-color: #ff505f !important;
}

.Active {
  color: #ffffff !important;
  background-color: #007dff !important;
}

.dialogPublishTitle {
  color: #0057b2;
  text-decoration: underline;
}

/*----------------- DASHBOARD - MANAGE SITE -----------------*/
.manageSiteH1 {
  font-size: 1.25rem !important;
  letter-spacing: 0.25px !important;
  font-weight: 500 !important;
  color: #4c4c4c;
}

.manageSiteH2 {
  font-size: 1rem !important;
  letter-spacing: 0.25px !important;
  font-weight: 400 !important;
  color: #4c4c4c;
}

.manageSiteUOSName {
  color: #0b79d0 !important;
  font-size: 20px !important;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.publishButton {
  text-decoration: underline !important;
  color: #0057b2;
  font-size: 14px !important;
}

.publishButton:hover {
  color: #001c3a;
  cursor: pointer;
}

.manageSiteSettingsH1 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.confirmManageDiv {
  margin: 1.5rem 0 !important;
}

.manageSiteSettingsEditTitle {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #4c4c4c;
}

.manageSiteSettingsEdit {
  font-size: 1rem !important;
  color: #4c4c4c;
}

.manageSiteSettingsDotPublish {
  color: #007dff;
}

.manageSiteSettingsDotUnpublish {
  color: #ff505f;
}

/*----------------- DASHBOARD - MANAGE SITE - ADD STAFF -----------------*/
#vertical-tabpanel-0,
#vertical-tabpanel-1 {
  width: 600px;
}

.MuiPaper-root-MuiDialog-paper {
  height: 500px;
}

.MuiTabs-flexContainer {
  margin-top: 2rem;
}

.addStaffTitle {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #424242;
}

/*----------------- DASHBOARD - MANAGE SITE - ADD COHORT -----------------*/
.addCohortButton {
  float: right;
  margin-bottom: 1rem;
}

/*----------------- CREATE SITE -----------------*/
.questionTitle {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #424242 !important;
}

.questionButton {
  margin-right: 1rem !important;
}

.dialogTitleSub {
  color: #616161 !important;
  font-size: 0.875rem !important;
}
.dialogText {
  color: #202020 !important;
}

.dialogExit {
  float: right;
}

.dialogTextTitle {
  color: #202020 !important;
  font-weight: 500 !important;
}

.dialogSubText {
  margin-bottom: 2rem !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.875rem;
  color: #212b36;
}

.MuiStepLabel-labelContainer {
  text-align: center;
}

.searchButton {
  height: 40px;
}

.helperText {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 0.3rem !important;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  cursor: pointer !important;
}

.publishList {
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.MuiSelect-select-MuiInputBase-input-MuiInput-input {
  text-align: left;
}

/*----------------- ADD STAFF STEP -----------------*/
.roleDivider {
  margin: 1rem 0 !important;
}

.roleBox {
  width: 19% !important;
  border: 1px solid #d3dae6 !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  margin-right: 0.5rem !important;
}

.roleTitle {
  font-weight: 500 !important;
  color: #343741 !important;
}

.roleDescriptionDiv {
  margin: 1rem 0;
  height: 220px;
}

.roleDescription {
  font-size: 0.875rem !important;
}

/*----------------- DATEPICKER ---------------.-*/
.datePickerSubtitle {
  color: #4c4c4c;
  font-size: 12px;
}

.datePicker {
  font-family: 'Roboto';
  border: none;
  border-bottom: 2px solid #8e8e8e;
  font-size: 1rem;
  padding: 0.6rem;
  border-radius: 2px;
  width: 100%;
}

.datePicker:focus-visible {
  outline: none;
  background-color: #f2f2f2;
  transition: border-bottom 0.3s ease;
  border-bottom: 2px solid #1870c6;
}

.datePicker:hover {
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f2f2f2;
}

.react-datepicker__header {
  background-color: #ffffff !important;
}

.react-datepicker__day--selected {
  border-radius: 1rem !important;
  background-color: #007dff !important;
}

/*----------------- CONFIRM STEP -----------------*/
.confirmDivider {
  margin: 1rem 0 !important;
}

.informationDivider {
  margin-bottom: 0.5rem !important;
}

.confirmH3 {
  font-size: 20px !important;
  color: #707070 !important;
}

.confirmTitle {
  font-size: 12px !important;
  color: #707070 !important;
}

.confirmDate {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #424242 !important;
}

.confirmInformation {
  font-size: 14px !important;
  color: #202020 !important;
  font-weight: 500 !important;
}

.confirmDiv {
  margin-bottom: 1.5rem !important;
}

.confirmEditIcon {
  color: #0057b2;
  font-size: 16px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

