/*----------------- CREATE SITE -----------------*/
.questionTitle {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #424242 !important;
}

.questionButton {
  margin-right: 1rem !important;
}

.dialogTitleSub {
  color: #616161 !important;
  font-size: 0.875rem !important;
}
.dialogText {
  color: #202020 !important;
}

.dialogExit {
  float: right;
}

.dialogTextTitle {
  color: #202020 !important;
  font-weight: 500 !important;
}

.dialogSubText {
  margin-bottom: 2rem !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.875rem;
  color: #212b36;
}

.css-16ubnlw-MuiStepLabel-labelContainer {
  text-align: center;
}

.searchButton {
  height: 40px;
}

.helperText {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 0.3rem !important;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.css-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
  cursor: pointer !important;
}

.publishList {
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.publishButton {
  text-decoration: underline !important;
  color: #0057b2;
}

.publishButton:hover {
  color: #001c3a;
}

.roleDivider {
  margin: 1rem 0 !important;
}

.roleBox {
  width: 19% !important;
  border: 1px solid #d3dae6 !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  margin-right: 0.5rem !important;
}

.roleTitle {
  font-weight: 500 !important;
  color: #343741 !important;
}

.roleDescriptionDiv {
  margin: 1rem 0;
  height: 220px;
}

.roleDescription {
  font-size: 0.875rem !important;
}

.confirmDivider {
  margin: 1rem 0 !important;
}

.informationDivider {
  margin-bottom: 0.5rem !important;
}

.confirmH3 {
  font-size: 20px !important;
  color: #707070 !important;
}

.confirmTitle {
  font-size: 12px !important;
  color: #707070 !important;
}

.confirmDate {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #424242 !important;
}

.confirmInformation {
  font-size: 14px !important;
  color: #202020 !important;
  font-weight: 500 !important;
}

.confirmDiv {
  margin-bottom: 1.5rem !important;
}

.confirmEditIcon {
  color: #0057b2;
  font-size: 16px !important;
}
