/*----------------- DASHBOARD -----------------*/
.dashboardH1 {
  font-size: 2.125rem !important;
  letter-spacing: 0.25px !important;
  font-weight: 400 !important;
  color: #616161;
}

.dashboardH1Sub {
  color: #9e9e9e;
}

.cardTitle {
  color: rgba(0, 0, 0, 0.38);
}

.dashboardIcon {
  margin-right: 0.5rem;
}

.cardValue {
  margin-top: 3.875rem !important;
}

.cardContent {
  color: #616161;
  padding-bottom: 0.5rem !important;
  text-align: left;
}

/*----------------- DASHBOARD TABS -----------------*/
.tabH3,
.tabH3Sub {
  color: #616161;
  font-size: 0.875rem !important;
}

.tabH3 {
  font-weight: 500 !important;
  margin-bottom: 0.4rem !important;
}

.previewIcon {
  color: #0057b2;
}

.inactiveChip {
  color: #ffffff !important;
  background-color: #ff505f !important;
}

.activeChip {
  color: #ffffff !important;
  background-color: #007dff !important;
}
